<template>
  <button
    :class="classStr"
    data-cy="signup-button"
    type="button"
    @click="signUpHandler"
  >
    Sign Up
  </button>
</template>

<script setup lang="ts">
const { signIn } = useAuth();
const signUpHandler = async () => {
  const queryParams: Record<string, string> = {
    screen_hint: "signup",
  };
  const ref = useRoute().query.ref;
  if (typeof ref === "string") {
    queryParams.referrer = ref;
  }
  await signIn(
    "auth0",
    {
      callbackUrl: "/",
      redirect: false,
    },
    queryParams,
  );
};
const { classStr } = defineProps({
  classStr: {
    type: String,
    default:
      "rounded-md bg-primary-50 px-3.5 py-2.5 text-sm font-semibold text-primary-400 shadow-sm hover:bg-primary-100",
  },
});
</script>
